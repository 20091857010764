import React, { Dispatch, SetStateAction } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { Dividor } from "../../../components/ui/line"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  useCheckoutState,
  useCheckoutDispatch,
} from "../../state/CheckoutContext"
import { removeFromCart } from "../../events"

import { Button } from "../../../components/ui/Button"
import { CartProduct, Product } from "../../../ts/interfaces"
import ProductPrice, {
  Inventory,
} from "../../../components/products/ProductPrice"
import { getCartProduct, getStripeId } from "../../../utils/stripejs"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import CartItemQuantity from "./CartItemQuantity"
import {
  getSubscriptionInCartTrialDays,
  isHeadsetInCart,
  isHeadsetSubscriptionPromoApplicable,
  isSubscriptionInCart,
} from "../../shared/calcStateItems"
import GiftCheckbox from "./GiftCheckbox"
import MembershipPromoDetailsTrigger from "../../../components/MembershipPromoDetailsTrigger"
import { useProducts } from "../../../hooks/useProducts"

interface Props {
  cartProd: CartProduct
  quantity: number
  showActions: boolean
  isGiftHeadset: boolean
  setIsGiftHeadset: Dispatch<SetStateAction<boolean>>
  setIsMembershipDescriptionModalOpen: Dispatch<SetStateAction<boolean>>
}

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ItemDetailContainer = styled.div<{ isMobile: boolean }>`
  font-size: 0.9rem;
  font-weight: 200;
  color: var(--color-grey-4);
  width: 100%;
  h2 {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--color-purple);
    /* margin: ${props => (props.isMobile ? "0" : "2rem")}; */
  }
  h4 {
    color: var(--color-dark-blue);
    font-size: 1rem;
  }
`

const ThumbImage = styled(props => <GatsbyImage {...props} />)`
  width: ${props => (props.isMobile ? "75px" : "125px")};
  height: ${props => (props.isMobile ? "75px" : "125px")};
  margin-right: ${props => (props.isMobile ? ".5em" : "1.5em")};
`

const RemoveAdjustContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  background-color: ${props =>
    props.isMobile ? "var(--color-grey-2)" : "transparent"};
  border-radius: ${props => (props.isMobile ? "10px" : "0px")};
  padding: ${props => (props.isMobile ? "5px 0" : "0px")};
  width: ${props => (props.isMobile ? "100%" : "auto")};
`

const RemoveContainer = styled.div``

const ItemText = styled.span<{ isMobile: boolean }>`
  font-size: 1rem;
  margin: ${props => (props.isMobile ? "0 !important" : ".5rem !important")};
  display: ${props => (props.isMobile ? "flex" : "inline-block")};
`

const InventoryContainer = styled.div`
  padding: 0.5rem 0 0rem 0;
`

const ItemActions = styled.div<{ showGiftToggle: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? "column" : "row")};
  align-items: ${props => (props.isMobile ? "flex-start" : "center")};
  justify-content: ${props =>
    props.showGiftToggle ? "space-between" : "flex-end"};
  color: #808080;
`

const MobileProductPriceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const ProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductName = styled.h2<{ isMandatoryMembershipItem: boolean }>`
  display: flex;
  align-items: center;
  color: ${props =>
    props.isMandatoryMembershipItem
      ? "var(--color-grey-4) !important"
      : "var(--color-purple)"};
`

const ProductNameSubtext = styled.div`
  margin-top: 0.2rem;
  color: #808080;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
`

const MembershipInfoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

const CartItem = ({
  cartProd,
  quantity,
  showActions,
  isGiftHeadset,
  setIsGiftHeadset,
  setIsMembershipDescriptionModalOpen,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const checkoutDispatch = useCheckoutDispatch()
  const checkoutState = useCheckoutState()
  const { currency, cartItems, couponDiscountDays } = checkoutState

  if (cartProd === null || cartProd === undefined) {
    return <div />
  }
  const price = cartProd.prices[currency]

  if (price === null) {
    return <div />
  }
  const data = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          enableGifts
          membershipMandatory
        }
        membership {
          promoDetailsTitle
        }
      }
    }
  `)

  const products = useProducts()

  const product: Product = products.filter((prd: Product) => {
    const stripeId = getStripeId(prd.stripeId, prd.stripeTestId)
    return stripeId === cartProd.id
  })[0]

  const priceObj = {
    CAD_List_Price: product?.CAD_List_Price!,
    CAD_Discount: product?.CAD_Discount!,
    USD_List_Price: product?.USD_List_Price!,
    USD_Discount: product?.USD_Discount!,
    depositHeading: product?.depositHeading!,
    priceHeading: product?.priceHeading!,
    stripeId: product?.stripeId!,
    stripeTestId: product?.stripeTestId!,
    inventoryBreakpoints: product?.inventoryBreakpoints,
    isBilledMonthly: product?.isBilledMonthly!,
    isBilledYearly: product?.isBilledYearly!,
  }

  const trialDays = getSubscriptionInCartTrialDays(cartItems)

  // TODO: extract this
  const stripeId = getStripeId(priceObj.stripeId, priceObj.stripeTestId)
  const stripeProduct = getCartProduct(stripeId)
  const isHeadset = stripeProduct?.metadata?.bladeSKU === "SEN_HEADSET_V1"
  const isMembershipItem = product.isBilledYearly || product.isBilledMonthly // TODO: Confirm if we want to do this another way
  const isMembershipMandatory = data.strapi.siteConfig.membershipMandatory
  const isMandatoryMembershipItem =
    isMembershipItem && isMembershipMandatory && isHeadsetInCart(cartItems)
  const showGiftToggle =
    data.strapi.siteConfig.enableGifts &&
    isHeadset &&
    (!isSubscriptionInCart(cartItems) || isMembershipMandatory)

  const { promoDetailsTitle } = data?.strapi?.membership

  const getProductNameSubtext = () => {
    if (product.isBilledYearly) {
      return `*Cancel anytime - $${Math.round(
        price.unit_amount / 100 / 12
      )} per month, billed yearly after ${Math.max(
        isHeadsetSubscriptionPromoApplicable(cartItems) ? trialDays : 0,
        couponDiscountDays
      )} days`
    } else {
      return `*Cancel anytime - $${Math.round(
        price.unit_amount / 100
      )} billed monthly after ${Math.max(
        isHeadsetSubscriptionPromoApplicable(cartItems) ? trialDays : 0,
        couponDiscountDays
      )} days`
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ItemContainer>
            <ItemDetailContainer isMobile={isMobile}>
              <FlexBetween>
                <Grid container spacing={1}>
                  <Grid item xs={9} md={6} lg={6}>
                    <ItemContainer>
                      {!isMandatoryMembershipItem && (
                        <ThumbImage
                          isMobile={isMobile}
                          image={
                            product &&
                            product.featuredImage &&
                            product.featuredImage.imageFile &&
                            product.featuredImage.imageFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={
                            product &&
                            product.featuredImage &&
                            product.featuredImage.alternativeText
                          }
                          objectFit="contain"
                          objectPosition="50% 50%"
                        />
                      )}
                      <ProductNameContainer>
                        <ProductName
                          isMandatoryMembershipItem={isMandatoryMembershipItem}
                        >
                          {isMembershipItem &&
                          (isMandatoryMembershipItem ||
                            isHeadsetSubscriptionPromoApplicable(cartItems) ||
                            couponDiscountDays > 0)
                            ? `${Math.max(
                                isHeadsetSubscriptionPromoApplicable(cartItems)
                                  ? trialDays
                                  : 0,
                                couponDiscountDays
                              )} day ${
                                isMandatoryMembershipItem
                                  ? "membership included"
                                  : "trial membership"
                              }`
                            : product.name}
                          {isMobile &&
                            quantity >= 1 &&
                            !showActions &&
                            cartProd.prices["cad"]?.type !== "recurring" && (
                              <span
                                style={{
                                  margin: "0 8px",
                                  fontSize: "1rem",
                                  marginLeft: "1rem",
                                  fontWeight: 400,
                                }}
                              >
                                {showActions ? quantity : `x ${quantity}`}
                              </span>
                            )}
                        </ProductName>
                        {isMembershipItem &&
                          (isMandatoryMembershipItem ||
                            isHeadsetSubscriptionPromoApplicable(cartItems) ||
                            couponDiscountDays > 0) && (
                            <ProductNameSubtext>
                              {getProductNameSubtext()}
                            </ProductNameSubtext>
                          )}
                      </ProductNameContainer>
                    </ItemContainer>
                  </Grid>
                  {!isMandatoryMembershipItem && (
                    <Grid item xs={3} md={6} lg={6}>
                      {isMobile && price && (
                        <MobileProductPriceContainer>
                          <ProductPrice
                            priceObj={priceObj}
                            isMobile={true}
                            hideFrontRow={true}
                          />
                        </MobileProductPriceContainer>
                      )}
                      {!(isMobile && !showActions) && (
                        <ItemDetailContainer isMobile={isMobile}>
                          {product?.shortDescription && (
                            <p>{product.shortDescription}</p>
                          )}
                          {price && !isMobile && (
                            <ProductPrice
                              priceObj={priceObj}
                              small={true}
                              hideFrontRow={true}
                            />
                          )}
                        </ItemDetailContainer>
                      )}
                    </Grid>
                  )}
                  {isMandatoryMembershipItem && (
                    <Grid item xs={3} md={6} lg={6}>
                      <MembershipInfoContainer>
                        <MembershipPromoDetailsTrigger
                          setIsMembershipDescriptionModalOpen={
                            setIsMembershipDescriptionModalOpen
                          }
                          onClickText={promoDetailsTitle}
                        />
                      </MembershipInfoContainer>
                    </Grid>
                  )}
                </Grid>
              </FlexBetween>
            </ItemDetailContainer>
          </ItemContainer>
        </Grid>

        {showActions && !isMandatoryMembershipItem && (
          <Grid item xs={12}>
            <ItemActions showGiftToggle={showGiftToggle} isMobile={isMobile}>
              {showGiftToggle && (
                <GiftCheckbox
                  isChecked={isGiftHeadset}
                  setIsChecked={setIsGiftHeadset}
                />
              )}
              <RemoveAdjustContainer isMobile={isMobile}>
                {(cartProd.prices?.cad as any).type !== "recurring" && (
                  <CartItemQuantity
                    showActions={showActions}
                    productId={cartProd.id}
                    currentQuantity={quantity}
                  />
                )}
                <RemoveContainer>
                  <ItemText isMobile={isMobile}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {quantity >= 1 && showActions && (
                        <Button
                          onClick={() =>
                            removeFromCart(
                              cartProd.id,
                              checkoutState,
                              checkoutDispatch
                            )
                          }
                          type="text"
                          icon="trash"
                          size="control"
                          title="Remove"
                          sectionId={cartProd.id}
                          id="remove"
                        />
                      )}
                    </div>
                  </ItemText>
                </RemoveContainer>
              </RemoveAdjustContainer>
            </ItemActions>
          </Grid>
        )}
        {isMobile && price && (
          <Grid item xs={12}>
            <InventoryContainer>
              <Inventory
                inventoryBreakpoints={priceObj.inventoryBreakpoints}
                priceObj={priceObj}
              />
            </InventoryContainer>
          </Grid>
        )}
      </Grid>
      <Dividor color="var(--color-grey-2)" />
    </>
  )
}

export default CartItem
